@media print {
  @page {
    margin: 0.5cm !important;
    size: a5 !important;
  }

  body {
    &.app-body {
      min-width: 900px !important;
      overflow: hidden !important;
    }
  }

  .media-print-hide {
    &#{&} {
      visibility: hidden;
    }
  }

  .media-print-remove {
    &#{&} {
      display: none;
    }
  }

  .scrollable-container {
    &#{&} {
      height: auto !important;
      max-width: 100%;
      min-width: 100%;
      position: relative !important;
      top: 0 !important;
      width: 100% !important;
    }
  }

  .page-container {
    &#{&} {
      min-height: auto !important;
    }
  }

  // MUI specific - start
  .MuiContainer-root {
    &#{&} {
      max-width: 100%;
    }
  }
  // MUI specific - end
}

@media screen {
  .media-screen-hide {
    visibility: hidden;
  }

  .media-screen-remove {
    display: none;
  }
}
